import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from 'src/app/applications/dashboard/dashboard-routing.module';
import { SpaceRoutingModule } from './modules/space/space-routing.module';
import { AccountSettingsRoutingModule } from './modules/account-settings/account-settings-routing.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { BillingAccountService } from './modules/space/services/billing-account.service';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SpaceRoutingModule,
    AccountSettingsRoutingModule,
    TranslationModule.forRoot('assets/i18n/errors/'),
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    BillingAccountService,
  ],
})
export class DashboardModule {}
