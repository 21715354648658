import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {WINDOW} from "src/app/core/inject-tokens/window.token";

@Component({
  selector: 'app-trustpilot-widget',
  templateUrl: './trustpilot-widget.component.html',
  styleUrls: ['./trustpilot-widget.component.scss']
})
export class TrustpilotWidgetComponent implements AfterViewInit {

  @ViewChild('widget',  { static: false }) widget?: ElementRef;

  ngAfterViewInit(): void {
    this.loadTrustpilotWidget()
  }

  private loadTrustpilotWidget(): void {
    if (!this.widget?.nativeElement) {
      return ;
    }
    return (window as any).Trustpilot.loadFromElement(this.widget?.nativeElement, true)
  }
}
