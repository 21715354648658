export const environment = {
  chatperk_sdk: 'https://cdn.chatperk.ai/sdk/chatperk-sdk.min.js',
  google_api_key: '',
  places_key: '',
  production: false,
  version: '0.0.1 (beta)',
  graphql_endpoint: 'https://graph.chatperk.ai/graphql',
  url: 'https://files.chatperk.ai',
  skip_auth: false,
  integrations: {
    slack_redirect_url:
    'https://slack.com/oauth/v2/authorize?client_id=8119852339489.8109688446340&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.public,commands,files:read,files:write,groups:history,groups:read,im:history,im:write,im:read,mpim:history,mpim:read,mpim:write,pins:read,reactions:read,reactions:write,users:write&user_scope=dnd:read,emoji:read,team:read,usergroups:read,users:read,users:read.email',
    meta_app_id: '1502953207010133',
    whatsapp_config_id: '897066068679630',
    messenger_config_id: '8515533035208604',
    instagram_config_id: '27779841204963499',
    wordpress_plugin_url: 'https://wordpress.com/plugins/chatperk'
  },
  firebase: {},
  STRIP_PUBLISHABLE_KEY:
    'pk_live_51Q1PMzKvhXcNDawwvkjFfXlvkgrlGG8IkPpeSGVK2nNHbU3PEUxJMY5lYMlw3ZETZ7ViRkD96MhcJ58pvmjI6xCF00DOuAuDKf',
  STRIPE_REDIRECT_URL: 'https://app.chatperk.ai/space/payment-status',
  annualDiscount: 2, //TODO move to another place
  annualDiscountText: '2 Months', //TODO move to another place
  website_url: "https://chatperk.ai",
  APP_SUMO_REVIEW_LINK: "https://appsumo.com/products/chatperk/#reviews"
};
