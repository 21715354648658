import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map, Subject, switchMap, takeUntil } from 'rxjs';
import { EPaymentIntentStatus } from 'src/app/chatperk-core/enums/EPaymentIntentStatus';
import { EStripePaymentIntentStatus } from 'src/app/chatperk-core/enums/EStripePaymentIntentStatus';
import {
  IBillingAccount,
  IPaymentMethod,
  ISubscription,
} from 'src/app/chatperk-core/interfaces';
import {
  IInvoice,
  IPaymentIntent,
} from 'src/app/chatperk-core/interfaces/invoice.interfaces';
import { StripeError } from '@stripe/stripe-js';
import { TranslationService } from 'src/app/kernel/translations/translation.service';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { Location } from '@angular/common';
import { IAfterSubmissionEmitEvent } from 'src/app/shared/payment-method/interfaces/payment-method.interfaces';
import { PaymentMethodsService } from '../../services/payment-methods.service';
import { BackEndError } from 'src/app/chatperk-core/models/backend-error.model';
import { EPaymentsProvider } from 'src/app/chatperk-core/enums/EPaymentsProvider';
import { EBillingInvoiceStatus } from 'src/app/chatperk-core/enums/EBillingInvoiceStatus';
import { PaymentService } from 'src/app/shared/subscription-shared/services/payment.service';
import { SubscriptionService } from 'src/app/shared/subscription-shared/services/subscription.service';
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {SUBSCRIPTION_CREATED_GTM_EVENT} from "src/app/core/gtm-events/subscription-created";
import {AuthService} from "src/app/chatperk-core/services/auth.service";
import { environment } from 'src/environments/environment';

const ICONS_BY_PAYMENT_STATUS = {
  [EPaymentIntentStatus.Succeeded]: 'fa-check',
  [EPaymentIntentStatus.Processing]: 'fa-clock',
  [EPaymentIntentStatus.RequiresCapture]: 'fa-clock',
  [EPaymentIntentStatus.RequiresConfirmation]: 'fa-info',
  [EPaymentIntentStatus.RequiresAction]: 'fa-shield-halved',
  [EPaymentIntentStatus.RequiresPaymentMethod]: 'fa-times',
  [EPaymentIntentStatus.Canceled]: 'fa-times',
};

const COLORS_BY_PAYMENT_STATUS = {
  [EPaymentIntentStatus.Succeeded]: '#34c481',
  [EPaymentIntentStatus.Processing]: '#4f566b',
  [EPaymentIntentStatus.RequiresCapture]: '#4f566b',
  [EPaymentIntentStatus.RequiresConfirmation]: '#4f566b',
  [EPaymentIntentStatus.RequiresAction]: '#4f566b',
  [EPaymentIntentStatus.RequiresPaymentMethod]: 'red',
  [EPaymentIntentStatus.Canceled]: '#cfbe29',
};

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public readonly eStripePaymentIntentStatus = EStripePaymentIntentStatus;
  public readonly ePaymentIntentStatus = EPaymentIntentStatus;

  public originalPaymentMethod?: IPaymentMethod;
  public paymentMethod?: IPaymentMethod;
  public paymentIntent?: IPaymentIntent;
  public invoice!: IInvoice;
  public isPayLoading = false;
  public selectPaymentMethodIsOpened = false;
  public paymentMethods: IPaymentMethod[] = [];
  public addPaymentMethodIsOpened = false;
  public paymentFormIsOpened = false;
  public setupClientSecret?: string;
  public paymentClientSecret?: string;
  public myBillingAccount?: IBillingAccount;
  public appSumoReviewLink = environment.APP_SUMO_REVIEW_LINK;
  public setupClientSecretObservable = this.paymentMethodsService
    .initiateSetup()
    .pipe(map((r) => r.data));

  get amount() {
    return this.paymentIntent
      ? this.paymentIntent.amount
      : { amount: this.invoice.amountDue, currency: this.invoice.currency };
  }

  get isRequiredPayment() {
    return (
      this.paymentIntent &&
      ![
        EPaymentIntentStatus.Canceled,
        EPaymentIntentStatus.Succeeded,
        EPaymentIntentStatus.Processing,
      ].includes(this.paymentIntent.status)
    );
  }

  get status() {
    return (
      this.paymentIntent?.status ||
      (this.invoice.status === EBillingInvoiceStatus.Paid
        ? EPaymentIntentStatus.Succeeded
        : EPaymentIntentStatus.RequiresPaymentMethod)
    );
  }

  get icon() {
    return ICONS_BY_PAYMENT_STATUS[this.status];
  }

  get color() {
    return COLORS_BY_PAYMENT_STATUS[this.status];
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslationService,
    private messageService: MessageService,
    private paymentService: PaymentService,
    private subscriptionService: SubscriptionService,
    private paymentMethodsService: PaymentMethodsService,
    private location: Location,
    private gtmService: GoogleTagManagerService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadResolvedData();
    this.location.replaceState(this.location.path(true), '', {});
  }

  loadResolvedData() {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.invoice = data['invoice'];
      this.paymentMethods = data['paymentMethods'] ?? [];
      this.myBillingAccount = data['myBillingAccount'];
      this.paymentIntent = this.invoice.paymentIntent;
      this.originalPaymentMethod = this.paymentMethod = !!this.paymentIntent
        ?.paymentMethod
        ? this.paymentMethods.find(
            (pm) => this.paymentIntent!.paymentMethod!.id === pm.id
          )
        : undefined;
    });
  }

  goToDashboard() {
    this.router.navigate(['/']);
  }

  openSelectPaymentMethod() {
    this.selectPaymentMethodIsOpened = true;
  }

  closeSelectPaymentMethod() {
    this.selectPaymentMethodIsOpened = false;
  }

  confirmPayment(showConfirmation = false) {
    this.isPayLoading = true;
    this.paymentIntent!.paymentMethod = this.paymentMethod;
    this.paymentService
      .confirmPayment(this.paymentIntent!, this.invoice.id, showConfirmation)
      .pipe(
        switchMap((result) =>
          this.subscriptionService.syncMySubscription().pipe(map(() => result))
        ),
        finalize(() => (this.isPayLoading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe((paymentIntentResult) => {
        if (paymentIntentResult) {
          this.handlePaymentIntentResult(
            paymentIntentResult.paymentIntent,
            paymentIntentResult.error
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handlePaymentIntentResult(
    paymentIntent?: IPaymentIntent,
    error?: StripeError
  ) {
    if (paymentIntent) {
      this.invoice.paymentIntent = this.paymentIntent = paymentIntent;
      this.paymentMethod = this.originalPaymentMethod = this.paymentIntent?.paymentMethod;
    }

    if (error) {
      this.messageService.error(
        this.translateService.instant('errors.something_went_wrong'),
        this.translateService.instant('errors.summary')
      );
    }

    if (!error) {
      this.gtmService.pushTag(
        SUBSCRIPTION_CREATED_GTM_EVENT(
          this.authService.getUser()?.email,
          this.authService.getUser()?.activeSpace?.id,
          this.invoice.id,
          this.amount,
          this.invoice?.subscription?.plan?.name
        )
      )
    }
  }

  savePaymentMethod(res: IAfterSubmissionEmitEvent) {
    this.paymentMethodsService
      .add({
        externalRef: {
          refId: res.setupIntentRes!.setupIntent!.payment_method!.toString(),
          provider: EPaymentsProvider.Stripe,
        },
        billingInfo: res.billingInfo,
        isPrimary: res.isPrimary,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          // To update resolvers
          this.router.navigateByUrl(this.router.url, {
            replaceUrl: true,
            onSameUrlNavigation: 'reload',
          });
          this.messageService.success(
            this.translateService.instant('paymentMethod.addedSuccessfully'),
            this.translateService.instant('messages.done')
          );
          this.addPaymentMethodIsOpened = false;
          this.setupClientSecret = undefined;
        },
        error: (error: BackEndError) => {
          const msg = this.translateService.instant(
            error.graphQLErrors[0].extensions.formatted?.data.messageKey ??
              'errors.something_went_wrong'
          );
          const summary = this.translateService.instant('errors.summary');
          return this.messageService.error(msg, summary);
        },
      });
  }

  openPaymentForm() {
    this.paymentClientSecret = this.invoice.paymentIntent?.clientSecret;
    this.paymentFormIsOpened = true;
  }

  closePaymentForm() {
    this.paymentClientSecret = undefined;
    this.paymentFormIsOpened = false;
  }

  handleAfterPaymentSubmission(res: IAfterSubmissionEmitEvent) {
    const paymentIntent = res.paymentIntentRes!.paymentIntent!;

    this.invoice.paymentIntent =
      this.paymentService.mapPaymentIntentFromStripe(paymentIntent);

    return this.paymentMethodsService
      .add({
        externalRef: {
          refId: paymentIntent.payment_method!.toString(),
          provider: EPaymentsProvider.Stripe,
        },
        billingInfo: res.billingInfo,
        isPrimary: res.isPrimary,
      })
      .pipe(
        switchMap(() => this.subscriptionService.syncMySubscription()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.closePaymentForm();
        // this.router.navigateByUrl(this.router.url, {
        //   replaceUrl: true,
        //   onSameUrlNavigation: 'reload',
        // });
      });
  }
}
