import {IAmount} from "src/app/chatperk-core/interfaces";

export const SUBSCRIPTION_CREATED_GTM_EVENT = (
  email: string | undefined,
  space: string | undefined,
  invoice: string | undefined,
  price: IAmount | undefined,
  plan: string | undefined
) => ({
  event: 'conversion_event_subscribe_paid_1',
  category: 'User Actions',
  email,
  space,
  invoice,
  currency: price?.currency,
  value: price?.amount,
  subscription_plan: plan
})
