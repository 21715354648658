import {Inject, Injectable, NgZone} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { AuthService } from 'src/app/kernel/tools/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {WINDOW} from "src/app/core/inject-tokens/window.token";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private translateService: TranslateService,
    @Inject(WINDOW) private window: Window
  ) {}

  private handleError(code: number, message: string): void {
    const translatedMessage = this.translateService.instant(message);
    switch (code) {
      case 401:
        this.handleUnauthorizedError();
        break;
      default:
        this.messageService.clear();
        this.messageService.error(translatedMessage);
        break;
    }
  }

  private interceptResponse(event: HttpEvent<any>, request: HttpRequest<any>): void {
    if (!(event instanceof HttpResponse) || request.headers.has('ignore_error_notification')) {
      return ;
    }
    this.handleResponseErrors(event, (error) => {
      this.handleError(error.code, error.data?.messageKey || error.message || 'errors.something_went_wrong');
    });
  }

  private handleResponseErrors(event: HttpResponse<any>, cb: (error: any) => void): void {
    const data = Array.isArray(event.body) ? event.body : [event.body];
    data.forEach((record: any) => {
      if (!Array.isArray(record?.errors)) {
        return
      }
      record.errors.forEach((error: any) => error?.extensions?.formatted && cb(error?.extensions?.formatted));
    });
  }

  private handleUnauthorizedError(): void {
    // Optional: Logout the user if necessary
    this.authService.logout();
    // this.messageService.clear();
    this.window.location.reload();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        return this.interceptResponse(event, request)
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleError(error.status, 'errors.something_went_wrong');
        return throwError(() => error);
      })
    );
  }
}
